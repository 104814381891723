export default class FtArPaymentd {
    constructor(
        id,
        noUrut,
        cashAmountPay,
        uangMukaAmountPay,
        returAmountPay,
        giroAmountPay,
        transferAmountPay,
        potonganAmount,
        potonganNotes,
        ftArPaymenthBean,
        ftSaleshBean,
        returBean,
        ftransferBean,
        fgiroBean,
        accAccountPotonganBean,
        fuangMukaBean,
        fdivisionBean,
        ref,
        refDate,
        remark,
        created,
        modified,
        modifiedBy
    ) {
        this.id = id;
        this.noUrut = noUrut;
        this.cashAmountPay = cashAmountPay;
        this.uangMukaAmountPay = uangMukaAmountPay;
        this.returAmountPay = returAmountPay;
        this.giroAmountPay = giroAmountPay;
        this.transferAmountPay = transferAmountPay;
        this.potonganAmount = potonganAmount;
        this.potonganNotes = potonganNotes;
        this.ftArPaymenthBean = ftArPaymenthBean;
        this.ftSaleshBean = ftSaleshBean;
        this.returBean = returBean;
        this.ftransferBean = ftransferBean;
        this.fgiroBean = fgiroBean;
        this.accAccountPotonganBean = accAccountPotonganBean;
        this.fuangMukaBean = fuangMukaBean;
        this.fdivisionBean = fdivisionBean;
        this.ref = ref;
        this.refDate = refDate;
        this.remark = remark;
        this.created = created;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
    }
}
